import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

const TitleElem = styled.h3`
  font: 20px / 24px "Slate Light";
  text-transform: uppercase;
  margin-bottom: 15px;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    font: 28px / 34px "Slate Light";
    margin-bottom: 18px;
  }
`;

const PreTitle = ({ children }) => <TitleElem>{children}</TitleElem>;

export default PreTitle;
