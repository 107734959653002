import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../styles/variables";

const GreyElem = styled.article`
  width: 100%;
  background: ${colors.lightGray};
  padding: 14px 11px;
  margin-bottom: 69px;
  height: max-content;

  hr {
    border-color: #707070;
    margin-bottom: 20px;
  }

  a.button {
    margin-bottom: 34px;
    &:first-of-type {
      margin-top: 50px;
    }
  }

  a.button {
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    padding: 26px 24px;
    margin-bottom: 110px;

    a.button {
      margin-bottom: 34px;
      &:first-of-type {
        margin-top: 85px;
      }
    }
  }
`;

const GreyBox = ({ children }) => <GreyElem>{children}</GreyElem>;

export default GreyBox;
