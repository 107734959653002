import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";

export const InhaltElem = styled.article`
  font: 16px / 19px "Slate Light";
  p,
  li {
    font: 16px / 19px "Slate Light";
  }

  p {
    margin-bottom: 20px;
  }

  ul {
    margin-bottom: 35px;
  }

  b,
  strong {
    font-family: "Slate Medium";
  }

  li {
    margin-bottom: 4px;
  }

  h2 {
    margin-bottom: 12px;
  }

  h4 {
    font: 18px / 24px "Slate Medium";
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  a {
    color: ${colors.lightBlue};
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  img {
    max-width: 100%;
    height: auto !important;
  }

  &.center {
    text-align: center;
    margin-bottom: 80px;
    img {
      margin: 0 auto;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin-bottom: 68px;
    font: 18px / 24px "Slate Light";

    &.center {
      margin-bottom: 110px;
    }

    p,
    li {
      font: 18px / 24px "Slate Light";
    }
    p {
      margin-bottom: 24px;
    }
    ul {
      margin-bottom: 50px;
    }
    li {
      margin-bottom: 5px;
    }
    h4 {
      margin-bottom: 20px;
    }
  }
`;

const SmallerInhalt = ({ content, center }) => (
  // <InhaltElem dangerouslySetInnerHTML={{ __html: content }} />
  <InhaltElem className={center ? "center" : ""}>
    <h4>Topics</h4>
    <p>
      To register as a delegate, click on the link provided above, click on the
      booking form and fill in your details. Once registered you will receive an
      email asking you to click to confirm your booking and pay for your
      delegate place(s). Please do not forget to book your accommodation using
      the tab on the website. Preferential rates have been negotiated with
      various 3, 4 & 5 star hotels around Interlaken. For your information, all
      the social events will be held at the Victoria Jungfrau. The social events
      will start on Wednesday 19 January with a pre-conference drinks reception
      starting from 6.00 pm. On Thursday 20 January there will be a drinks
      reception followed by the gala dinner. You are invited to attend all these
      social events free as part of your delegate fee. Finally, if you are
      interested in sponsoring the conference, please contact Jackie Boole who
      can be reached at alpine@step.swiss We look forward to seeing you in
      Interlaken in January 2022.
    </p>
    <ul>
      <li>Economy & Finance</li>
      <li>The impact of the Corona crisis on the private client industry</li>
      <li>The impact of the Corona crisis on the private client industry</li>
      <li>The impact of the Corona crisis on the private client industry</li>
    </ul>
  </InhaltElem>
);

export default SmallerInhalt;
