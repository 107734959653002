import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";

const TitleElem = styled.h1`
  font: 34px / 40px "Slate Medium";
  margin-bottom: 60px;
  &.orange {
    color: ${colors.orange};
  }
  color: ${(props) =>
    colors[
      props.color
        ?.toLowerCase()
        .replace("-", "")
        .replace("/", "")
        .replace(" ", "")
        .replace(" ", "")
    ]} !important;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    font: 60px / 72px "Slate Medium";
  }
`;

const SmallerMainTitle = ({ children, color }) => (
  <TitleElem color={color}>{children}</TitleElem>
);

export default SmallerMainTitle;
