import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

const Grid = styled.div`
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    display: grid;
    grid-template-columns: 7fr 5fr;
    grid-gap: 80px;
  }
`;

const Grid75 = ({ children }) => <Grid>{children}</Grid>;

export default Grid75;
