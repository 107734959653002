import * as React from "react";

import { Helmet } from "react-helmet";
import { Page } from "../components/Page";
import Container from "../components/Container";
import DefaultLayout from "../layouts/default";
import { HtmlPageAttributes } from "../api";
import Grid47 from "../components/Grid.tsx/Grid47";
import ContentWrapper from "../components/Wrappers/ContentWrapper";
import Komittees from "../components/Komittees";
import SectionTitle from "../components/Text/SectionTitle";
import Grid75 from "../components/Grid.tsx/Grid75";
import SmallerMainTitle from "../components/Text/SmallerMainTitle";
import { InhaltElem } from "../components/Text/SmallerInhalt";
import Sponsors from "../components/Sponsors";
import GreyBox from "../components/GreyBox";
import PreTitle from "../components/Text/PreTitle";

interface PagePinboardTemplateProps {
  pageContext: {
    attributes: HtmlPageAttributes;
  };
}

const PagePinboardTemplate: React.FC<PagePinboardTemplateProps> = (props) => {
  const {
    pageContext: {
      attributes: { content, name, teaser, description, keywords, slug },
    },
  } = props;
  return (
    <DefaultLayout siteSlug={slug}>
      <Helmet>
        <title>{name} - STEP</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <Page>
        <ContentWrapper>
        <PreTitle>
          ADVERTISING PLATFORM FOR JOBS, NEWS AND ACTIVITIES
        </PreTitle>
            <SmallerMainTitle>{name}</SmallerMainTitle>


          <Grid75>
            <InhaltElem>
              {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
            </InhaltElem>

            <GreyBox>
              <InhaltElem>
            <div>
                {teaser && <div dangerouslySetInnerHTML={{ __html: teaser }} />}
              </div>
              </InhaltElem>
            </GreyBox>
          </Grid75>
        </ContentWrapper>

      </Page>
    </DefaultLayout>
  );
};

export default PagePinboardTemplate;
